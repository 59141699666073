@import "../../../../App.scss";

.Overlay {
}

.popover {
  max-width: 90%;

  @media (min-width: 768px) {
    max-width: 24rem !important;
  }
}

.popover-alert {
  margin: auto;
  padding: 0.5rem 0rem 0 0;

  .close-icon {
    cursor: pointer;
    margin-left: 0 !important;
    height: 28px;
    width: 28px;
  }

  .info-icon {
    margin-right: 0.1rem;
  }

  h2 {
    align-self: center;
    font-size: 1rem !important;
  }

  p {
    margin: 0.5rem 0;
  }
}
