@import "../../../../App.scss";

.HistoryPage {
  h6 {
    font-size: 0.85rem !important;
    margin-bottom: 0.75rem;
  }
}

.DownloadCsv {
  display: block;
  width: 100%;
  text-decoration: none !important;
}