@import "../../../../../App.scss";

.PrimaryButton {
  color: $white;
  background-color: $teal;
  border-color: $teal;
  border-radius: 3rem;
  font-size: 0.95rem;
  font-family: Apercu-Medium;
  margin: 1rem auto 1rem auto;
  padding: 0;
  height: 3rem;
  min-width: 8rem;
  max-width: 18rem;

  .button-icon {
    margin: -0.1rem 0.4rem 0 0;
    width: 15px;
    height: 15px;
  }
}

.PrimaryButton-outline {
  background-color: $white !important;
  color: $teal !important;
}

.PrimaryButton-row {
  margin-top: 0.5rem;
  min-width: 6rem !important;
  height: 2.15rem;
  width: 6rem !important;

  @media only screen and (min-width: 768px) {
    min-width: 6.25rem !important;
  }
}
