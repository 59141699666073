@import "../../../App.scss";

.HealthPromptAlert {
  .divider {
    margin: 0 auto 1rem auto;
    background-color: $light-gray;
    opacity: 0.3;
  }

  .alert-details-row {
    .name-row {
      justify-content: flex-start;

      h3 {
        text-align: left;
        font-size: 1rem;
      }

      .verified-icon {
        margin: 0.1rem 0 0 0.45rem;
        height: 15px;
        width: 15px;
      }
    }

    .time-stamp {
      font-size: 0.8rem;
      text-align: right;
    }

    .location-label {
      line-height: 0.3rem;
      font-size: 0.8rem;
      text-align: right;
      font-style: italic;
      font-weight: bold;
      color: $light-gray;
    }

    .location-label-at-home {
      color: $teal !important;
    }
  }

  .actions-container {
    background-color: $white-gray;
    margin: 1rem auto;
    padding: 1rem 1rem 0.5rem 1rem;
    z-index: 2;
    justify-content: center;

    .check-icon {
      margin: -0.2rem 0.25rem 0;
      height: 21px;
      width: 21px;
    }

    h6 {
      font-size: 0.9rem !important;
      color: $success;
      text-transform: capitalize;
    }
  }

  .buttons-container {
    justify-content: space-between;

    @media only screen and (min-width: 768px) {
      margin: 1rem auto;
    }
  }

  .change-buttons-row {
    margin: -1rem auto 0 auto;
  }
}
