@import '../../../../../App.scss';

.SecondaryButton {
  color: $teal !important;
  background-color: $white !important;
  border-color: $white !important;
  border-radius: 3rem !important;
  font-size: 0.85rem;
  font-family: Apercu-Medium;
  margin: 1rem auto 1rem auto;
  padding: 0;
  height: 2rem;
  min-width: 8rem;
  max-width: 18rem;
}