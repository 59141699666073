@import "../../../../../App.scss";

.TabButtons {
  height: 2rem;
  width: 16rem;
  margin: 1.5rem auto !important;

  .tab-button {
    height: 2.75rem;
    color: $teal;
    background-color: $white;
    border: 1px solid $teal;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-family: Apercu-bold;
  }

  .btn-primary:not(:disabled):not(.disabled):active {
    background-color:  $default !important;
  }

  .tab1 {
    border-radius: 3rem 0 0 3rem !important;
  }

  .tab2 {
    border-radius: 0 3rem 3rem 0 !important;
  }

  .selected {
    color: $white !important;
    background-color: $teal !important;
  }
}
