@import "../../../../App.scss";

.Card {
  margin: 0.65rem auto;
  border: 0.5px solid $nav-border !important;
  border-radius: 2px;
  min-width: 10rem;
  padding: 1rem 1rem 0 1rem;

  .arrow-icon {
    margin-top: 0.25rem;
    height: 15px;
    width: 15px;
    color: $teal;
  }

  .upside-down {
    transform: rotateX(180deg);
  }

  .row {
    padding: 0 1rem;
    justify-content: space-between;
  }

  .card-label {
    color: $teal;
    font-size: 0.95rem;
    margin-bottom: 1rem;
    font-family: Apercu-medium;
  }

  h6 {
    text-align: center;
    font-size: 0.9rem;
    color: $default;
  }

  p {
    text-align: left;
    font-size: 0.85rem;
  }

  a {
    color: $secondary-purple;
    text-decoration: underline;
    font-weight: bold;
  }
}
