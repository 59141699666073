@import "../../../../../App.scss";

.ListDivider {
  background-color: $white-gray;
  padding: 0.2rem 0.75rem 0rem 1rem;

  label {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: $faint-gray;
  }

  .row {
    padding: 0 1rem;
    justify-content: space-between;
  }

  .info-icon {
    margin-top: -0.2rem;
  }
}