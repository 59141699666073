@import "./colors.scss";

/* Fonts */

@font-face {
  font-family: Apercu-medium;
  src: url("./app/assets/fonts/Apercu-medium.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Apercu-bold;
  src: url("./app/assets/fonts/Apercu-bold.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Apercu-regular;
  src: url("app/assets/fonts/Apercu-regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Open-sans;
  src: url("app/assets/fonts/OpenSans-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: Roboto;
  src: url("app/assets/fonts/Roboto-Regular.ttf") format("opentype"); /* Safari, Android, iOS */
}

* {
  font-family: Apercu-regular;
}

body {
  background-color: $white-gray;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
p {
  color: $default;
}

h1,
h2,
h3 {
  font-family: Apercu-bold;
}

h4,
h5,
h6,
p,
a {
  font-family: Roboto;
}

.button {
  font-family: Apercu-bold !important;
}

button:active,
button:focus,
button:focus:active {
  background-image: none !important;
  outline: 0;
  box-shadow: none !important;
}

// Style App scrollbar
::-webkit-scrollbar {
  height: 0.25rem;
  width: 0.25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: $white-gray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $light-gray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $default;
}

/* Tooltip */
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $dark-gray;
}

.tooltip-inner {
  background-color: $dark-gray;
  border-radius: 1px;
}

.no-padding {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
