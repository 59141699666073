@import './App.scss';

$theme-colors: (
  "primary": $teal
);

@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $white-gray !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

svg {
  vertical-align: baseline;
}
