@import "../../../../App.scss";

.FeedbackModal {
  h2 {
    margin-top: 0.15rem;
    font-size: 1.2rem;
  }

  .subtitle {
    font-size: 0.85rem;
    color: $light-gray;
  }

  .modal-header {
    margin-bottom: -1rem;
    border-bottom: none;
  }

  .modal-body-header {
    font-size: 0.9rem;
    font-family: Apercu-medium;
    font-style: italic;
  }

  .react-stars {
    outline: none;

    span {
      margin-right: 0.5rem;
    }
  }

  .input-label {
    margin-top: 1.5rem;
    font-family: Apercu-medium;
    color: $default;
    opacity: 0.85;
    font-size: 0.85rem;
  }

  .input-sublabel {
    font-family: Apercu-medium;
    color: $light-gray;
    font-size: 0.75rem;
    margin: 0.4rem;
    font-style: italic;
  }

  .feedback-textarea {
    margin-bottom: 1.5rem;
  }

  textarea {
    font-size: 0.9rem;
    color: $default;
  }

  textarea::placeholder {
    font-size: 0.8rem;
    color: $light-gray;
    opacity: 0.8;
  }

  .checkbox-row {
    margin: -0.5rem 0 -1rem -0.5rem;
  }

  .checkbox {
    margin: 0.15rem 0.5rem;
  }

  .rc-checkbox-checked:hover .rc-checkbox-inner {
    border-color: $navy;
  }

  .rc-checkbox-checked .rc-checkbox-inner {
    border-color: $navy;
    background-color: $navy;
  }

  .rc-checkbox-checked .rc-checkbox-inner {
    box-shadow: inset 0 0 0 18px $navy;
  }

  .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: $navy;
  }

  .checkbox-label {
    font-size: 0.8rem;
    color: $default;
    opacity: 0.75;
    font-family: Apercu-bold;
  }

  input[type="email"] {
    font-size: 0.85rem;
    color: $default;
  }

  #formBasicEmail::placeholder {
    font-size: 0.8rem;
    color: $light-gray;
    opacity: 0.8;
  }

  .invalid-input {
    font-family: Apercu-bold;
    font-size: 0.8rem;
    color: $primary-dark;
  }

  .submit-button {
    background-color: $navy !important;
    width: 2rem !important;
  }

  .close-icon {
    cursor: pointer;
    height: 28px;
    width: 28px;
  }

  p {
    font-size: 0.8rem;
  }

  .success-container {
    text-align: center;

    .success-icon {
      width: 6rem;
      margin-top: 2rem;
    }

    h4 {
      color: $default;
      margin: 1rem 0 4rem 0;
    }
  }
}
