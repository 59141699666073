@import "../../../App.scss";

.Home {
  background-color: $white-gray;
  text-align: center;
  margin: auto;
  max-width: 35rem;

  @media (min-width: 992px) {
    max-width: 40rem;
  }

  h1 {
    margin-top: 1rem;
    font-size: 1.6rem;
  }

  .location-search {
    margin-top: 2rem;
    font-size: 1.5rem;
  }

  .tab-buttons-row {
    margin: 0 -1rem;
    margin-right: -0.5rem;
    align-content: center;

    .TabButtons {
      position: relative;
    }

    .feedback-icon {
      height: 25px;
      width: 25px;
      margin-top: 2rem;

      @media (max-width: 360px) {
        margin-left: -1rem;
        margin-right: 1.5rem;
      }

      @media (min-width: 361px) and (max-width: 450px) {
        margin-left: -3rem;
        margin-right: 2rem;
      }

      @media (min-width: 451px) and (max-width: 750px) {
        margin-left: -3.25rem;
        margin-right: 3.25rem;
      }

      @media (min-width: 751px) {
        margin-left: -4rem;
        margin-right: 4rem;
      }
    }
  }
}

// Feedback Tooltip styling separate
div.ToolTipPortal > div {
  @media (max-width: 360px) {
    left: 54% !important;
  }

  @media (min-width: 361px) and (max-width: 408px) {
    left: 58% !important;
  }

  @media (min-width: 409px) and (max-width: 499px) {
    left: 63% !important;
  }

  @media (min-width: 500px) and (max-width: 560px) {
    left: 64% !important;
  }

  @media (min-width: 561px) and (max-width: 720px) {
    left: 66% !important;
  }

  @media (min-width: 721px) and (max-width: 850px) {
    left: 61% !important;
  }

  @media (min-width: 851px) and (max-width: 1023px) {
    left: 59% !important;
  }

  @media (min-width: 1024px) and (max-width: 1100px) {
    left: 62% !important;
  }

  @media (min-width: 1101px) and (max-width: 1276px) {
    left: 60% !important;
  }

  @media (min-width: 1276px) {
    left: 58% !important;
  }

  .feedback-tooltip-message {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }
}
