@import "../../../../App.scss";

.AccordionList {

  .health-prompt-message {
    font-size: 0.9rem;
    text-align: left;
    margin-top: 0.2rem;
  }

  .team-member-icon {
    display: inline;
    justify-content: start;
  }

  .alerts-number {
    color: $default;
    font-family: Apercu-medium;
  }

  .times-container {
    margin-top: 1.5rem !important;
  }
}
