@import '../../../../App.scss';

.ModalPopup {
  h2 {
    margin-top: 0.15rem;
    font-size: 1rem;
  }

  .modal-header {
    margin-bottom: -1.5rem;
    border-bottom: none;
  }

  .close-icon {
    cursor: pointer;
    height: 28px;
    width: 28px;
  }

  p {
    font-size: 0.8rem;
  }
}