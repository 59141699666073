@import "../../../../App.scss";

.TodayPage {
  .link-row {
    align-items: center;
    margin-right: -1rem;
    height: 2rem;
    margin-bottom: 1rem;

    a {
      margin-left: -1rem;
      font-size: 0.75rem;
      text-align: left;
      width: 125%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      padding-right: 1rem;
    }
  }

  h6 {
    font-size: 0.85rem !important;
    margin-bottom: 0.75rem;
  }

  .info-icon {
    margin-top: -0.1rem !important;
  }

  .sms-text-container {
    margin-top: -1rem;
  }

  .switch-card-container {
    padding: 0;
    margin: 0 -2rem;
    .switch-title {
      text-align: left;

      h5 {
        font-weight: bold;
        font-size: 0.8rem;
        margin: auto 0;
      }
    }

    .switch-container {
      .switch-row {
        margin: 0 -0.15rem 0 0;
        padding: 0;
        justify-content: flex-end;
        align-items: center !important;

        img {
          margin: auto 0;
        }

        .toggle-switch {
          margin-right: 0.75rem;
        }
      }
    }

    margin-bottom: 0.5rem;

    .toggle-switch {
      margin-right: -4.5rem;
    }
  }
}
